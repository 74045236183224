<template>
  <v-container>
    <v-row></v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Dashboard",
  data: () => ({}),
  components: {},
  methods: {},
  computed: {},
  created() {
    //Lade Mandanten beim start
    if (this.loggedIn) {
      this.$store.dispatch("mandant/fetchMandantenList");
    }
  }
};
</script>
<style scoped></style>
